@tailwind base;
@tailwind components;
@tailwind utilities;


.stroke {
  color: transparent;
  -webkit-text-stroke: 3px #000000;
  text-align: center;
  font-size: 10em;
  text-transform: uppercase;
  display: inline-block;
}

.main-dropdown{
  box-shadow: 0 9px 9px rgba(0, 0, 0, 0.1); 
}

.header__area-2{
  background-color: #000000;
}

.price {
  background-color: black;
  border: 1px solid #ccc;
  padding: 30px;
  border-radius: 10px;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.price:hover {
  background-color: black;
  border-color: gray;
  color: white;
}

.about-bg-image{
  background-image: '../public/assets/img/banner1.png';
}


.collapse {
    display: none;
    visibility: visible;
}

.collapse.show {
    display: block;
}

@keyframes gradient-animation {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

.bg-animated-gradient {
  background-size: 200% 200%;
  animation: gradient-animation 10s ease infinite;
  background-image: linear-gradient(
      to bottom,
      #c9f31d,        /* Bright yellow-green */
      #d0f842,        /* Light green-yellow */
      #e0f95a,        /* Lighter yellow-green */
      #f1fa71,        /* Light yellow */
      #f7fbd1,        /* Very light yellow */
      #ffffff         /* White */
  );
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 0%; /* Start at top left */
  }
  25% {
    background-position: 50% 0%; /* Move to mid top */
  }
  50% {
    background-position: 100% 0%; /* Move to bottom left */
  }
  75% {
    background-position: 50% 100%; /* Move to mid bottom */
  }
  100% {
    background-position: 0% 100%; /* End at bottom left */
  }
}

.bg-animated-gradient {
  background-size: 200% 200%;
  animation: gradient-animation 20s ease-in-out reverse;
  background-image: linear-gradient(
    to bottom,
    #f3f8f4, /* Start color */
    #bac1ee, /* Intermediate color */
    #bfd4f0, /* Mid color */
    #cfe8ea, /* Intermediate color */
    #f3ccd5  /* End color */
  );
  background-position: 0% 0%;
  background-repeat: no-repeat;
}

.bg-animated-gradient-color {
  background-size: 200% 200%;
  animation: gradient-animation 20s ease-in-out reverse;
  background-image: linear-gradient(
    to bottom,
    #dad9e9, /* Start color */
    #f7d6d4, /* Intermediate color */
    #f3f8f4, /* Mid color */
    #bad9c7, /* Intermediate color */
    #f3f8f4  /* End color */
  );
  background-position: 0% 0%;
  background-repeat: no-repeat;
}


/* Custom gradient shadow */
.gradient-shadow {
  position: relative;
  overflow: hidden;
}

.gradient-shadow::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  background: linear-gradient(145deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
  z-index: -1;
  transition: box-shadow 0.3s ease-in-out;
}

.gradient-shadow:hover::before {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Custom shadow color */
.custom-shadow {
  box-shadow: 0 10px 15px -3px rgba(20, 184, 166, 0.5), 0 4px 6px -4px rgba(20, 184, 166, 0.5);
}

* {
  animation: none !important;
  /* transition: none !important; */
}

.trns-text {
  background: url(../public/assets/img/bg_gradient.gif) no-repeat bottom center;
    background-color: rgba(0, 0, 0, 0);
    background-size: auto;
    background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-size: cover;
}

/* @font-face {
  font-family: 'Sora';
  src: url('../public/Sora-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */


/* 
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */
